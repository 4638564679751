<template>
  <main>
    <div>
      <trac-back-button> <h2 class="text-xs">Settings</h2></trac-back-button>
      <h1 class="font-medium mt-2">Employee details</h1>
      <div class="mt-6 bg-white flex w-full white-gradient px-8 py-6">
        <div>
          <div class="w-64 bg-gray-400 h-56 rounded-tl-sm rounded-tr-sm">
            <img v-if="false" class="w-full h-full object-cover" alt="" />
          </div>
          <div
            class="w-64 flex items-center justify-center py-3 cursor-pointer bg-gray-500 text-sm text-white"
          >
            Add Picture
          </div>
        </div>
        <div class="ml-10 pl-10 w-full border-l">
          <trac-custom-header>
            <template slot="leading">Select</template>
            <template slot="trailing"> Roles</template>
          </trac-custom-header>
          <div class="flex items-center">
            <div class="text-sm">Select Store(S)</div>
            <div class="ml-6">
              <trac-radio>Main</trac-radio>
            </div>
          </div>
          <div class="mt-10">
            <div class="border relative p-4 rounded">
              <div
                class="absolute top-0 -mt-2 left-0 ml-2 bg-white px-2 text-sm"
              >
                Roles
              </div>
              <div class="mt-2 flex items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8.16267"
                    cy="8.16267"
                    r="7.16267"
                    stroke="#253B95"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.1164 17.1164L13.2217 13.2217"
                    stroke="#253B95"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div
                  class="ml-3 text-primaryBlue text-sm uppercase font-medium"
                >
                  Search
                </div>
              </div>
              <div class="mt-5 w-64">
                <trac-dropdown
                  title="Select Role"
                  class="w-full"
                ></trac-dropdown>
              </div>
              <div class="mt-4">
                <trac-button variant="outline">New Role</trac-button>
              </div>
            </div>

            <div></div>
          </div>
          <div class="flex justify-end items-end mt-10">
            <trac-button> Save Changes</trac-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {

}
</script>

<style>
</style>>